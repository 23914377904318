import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import { StaticPageTemplate } from './static-page-tpl';

const CookiesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout showContact={false} showNewsletter={false} showFooter={false} meta={frontmatter.meta}>
      <StaticPageTemplate {...frontmatter} />
    </Layout>
  );
};

CookiesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }), 
  }),
};

export default CookiesPage;

export const cookiesPageQuery = graphql`
  query CookiesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "cookies-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }

        title
        content
      }
    }
  }
`;
